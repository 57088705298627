import React from "react";
import { useLocation } from "react-router-dom"
import { server } from "../api"

function GeneratedPost(props) {
  const { search } = useLocation()
  const params = new URLSearchParams(search)
  const id = params.get("id")

  return <iframe width="100%" style={{ height: '100vh'}} src={`${server}/ssr/write-post?id=${id}`} frameBorder="0"></iframe>
}

export default GeneratedPost;
