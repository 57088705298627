import React, { useEffect } from "react"
import { server } from "../api"
import { useNavigate } from "react-router-dom"

export default function () {
  const navigate = useNavigate()

  // This hook is listening an event that came from the Iframe
  useEffect(() => {
    const handler = (ev) => {

      if (typeof ev.data !== "object") return
      if (!ev.data.type) return
      if (ev.data.type !== "open") return
      if (!ev.data.message) return

      navigate(`/generated-post?id=${ev.data.message}`)
    }

    window.addEventListener("message", handler)

    // Don't forget to remove addEventListener
    return () => window.removeEventListener("message", handler)
  }, [])

  return <iframe width="100%" style={{ height: '100vh'}} src={`${server}/ssr/all-posts`} frameBorder="0"></iframe>
}
