import React, { useEffect, useState } from "react"
import { server } from "../api"
import { useModal } from "mui-modal-provider"
import { Button, TextField, Typography } from "@mui/material"
import { savePECSRRPost } from "../api/client"
import Autocomplete from "@mui/material/Autocomplete"
import Chip from "@mui/material/Chip"
import Box from "@mui/material/Box"
import { predefinedTags } from "./PecsRRPage"
import Modal from "@mui/material/Modal"
import { saveWrittenPost } from "../api/api"
import { useNavigate } from "react-router-dom"

function sendMessage(message) {
  const iframe = document.querySelector("iframe")
  iframe.contentWindow.postMessage(message, "*")
}

const SavePostModal = ({ formData, ...props }) => {
  const [tags, setTags] = useState([])
  const navigate = useNavigate()

  return (
    <Modal {...props}>
      <Box
        sx={{
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          width: 400,
          bgcolor: "#212C44",
          borderRadius: "4px",
          boxShadow: 24,
          p: 4,
        }}
      >
        <Typography variant="h6" id="modal-title" style={{ color: "white" }}>
          Save PECSRR post?
        </Typography>
        <form
          onSubmit={async (e) => {
            e.preventDefault()
            await saveWrittenPost({ ...formData, tags })
            navigate({ pathname: "/all-posts" })
            // sendMessage(tags)
            props.onClose()
          }}
        >
          <TextField
            label="Client Name"
            value={formData.clientName}
            fullWidth
            style={{ marginTop: "15px", marginBottom: "15px" }}
            InputLabelProps={{
              style: { color: "#C0C9E1" },
            }}
            InputProps={{
              style: { color: "#C0C9E1", background: "#3B4C6E" },
            }}
          />
          <TextField
            label="Client Company"
            value={formData.clientCompany}
            fullWidth
            style={{ marginTop: "15px", marginBottom: "15px" }}
            InputLabelProps={{
              style: { color: "#C0C9E1" },
            }}
            InputProps={{
              style: { color: "#C0C9E1", background: "#3B4C6E" },
            }}
          />
          <Autocomplete
            multiple
            id="tags-filled"
            options={predefinedTags.map((option) => option.label)}
            freeSolo
            value={tags}
            onChange={(event, newValue) => {
              setTags(newValue)
            }}
            renderTags={(value, getTagProps) =>
              value.map((option, index) => (
                <Chip
                  variant="outlined"
                  label={option}
                  {...getTagProps({ index })}
                  style={{
                    marginBottom: "10px",
                    marginTop: "10px",
                    color: "white",
                  }}
                />
              ))
            }
            renderInput={(params) => (
              <TextField
                {...params}
                variant="filled"
                label="Tags"
                placeholder="Add tags"
                InputLabelProps={{
                  style: { color: "white" },
                }}
                InputProps={{
                  ...params.InputProps,
                  style: { color: "white", background: "#3B4C6E" },
                }}
              />
            )}
          />
          <Button
            type="submit"
            variant="contained"
            sx={{
              bgcolor: "#F16334",
              color: "white",
              "&:hover": {
                bgcolor: "#F16334",
              },
              marginTop: "15px",
            }}
          >
            Save
          </Button>
        </form>
      </Box>
    </Modal>
  )
}

export default function () {
  const [message, setMessage] = useState("")
  const { showModal } = useModal()

  // This hook is listening an event that came from the Iframe
  useEffect(() => {
    const handler = (ev) => {

      if (typeof ev.data !== "object") return
      if (!ev.data.type) return
      if (ev.data.type !== "button-click") return
      if (!ev.data.message) return

      showModal(SavePostModal, { formData: ev.data.message })
      // console.log(ev.data.message)
      setMessage(ev.data.message)
    }

    window.addEventListener("message", handler)

    // Don't forget to remove addEventListener
    return () => window.removeEventListener("message", handler)
  }, [])

  return (
    <iframe
      width="100%"
      style={{ height: "100vh" }}
      src={`${server}/ssr/write-post`}
      frameBorder="0"
    ></iframe>
  )
}
