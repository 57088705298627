import React, { useContext } from "react"
import clout_selling_logo from "./../../images/clout_selling_logo.png"
import { useNavigate } from "react-router-dom"
import {
  List,
  ListItemButton,
  ListItemText,
  ListSubheader,
  Paper,
  ThemeProvider,
} from "@mui/material"
import Box from "@mui/material/Box"
import MouseoverMenu from "../MouseoverMenu"
import { darkTheme } from "../../theme"
import ChevronRightIcon from "@mui/icons-material/ChevronRight"
import { UserContext } from "../../context/UserContext"
import { ac, RESOURCE } from "../../access-control"
import { canPickArticlesNow } from "../../helpers/TotoArticleHelper"

const SIDEBAR_ITEM = {
  CLIENT_SUBHEADER: "CLIENT_SUBHEADER",
  ONBOARDING: "ONBOARDING",
  NOTIFICATIONS: "NOTIFICATIONS",
  CALENDAR: "CALENDAR",
  PECSRR: "PECSRR",
  POST: "POST",
  CREATE: "CREATE",
  VIEW: "VIEW",
  CREATE_PECSRR: "CREATE_PECSRR",
  ALL_PECSRR: "ALL_PECSRR",
  SCHEDULE_LI_POST: "SCHEDULE_LI_POST",
  ALL_LI_POSTS: "ALL_LI_POSTS",
  CRUD_SUBHEADER: "CRUD_SUBHEADER",
  CREATE_CLIENT: "CREATE_CLIENT",
  CREATE_COMPANY: "CREATE_COMPANY",
  CREATE_USER: "CREATE_USER",
  ALL_CLIENTS: "ALL_CLIENTS",
  ALL_COMPANIES: "ALL_COMPANIES",
  SETTINGS_SUBHEADER: "SETTINGS_SUBHEADER",
  SETTINGS: "SETTINGS",
  SUPERADMIN_SUBHEADER: "SUPERADMIN_SUBHEADER",
  PROMPTS: "PROMPTS",
  POST_PROMPTS: "POST_PROMPTS",
  OPENAI_KEY: "OPENAI_KEY",
  PLAYGROUND: "PLAYGROUND",
  TOTO_ARTICLES: "TOTO_ARTICLES",
  PICK_TOTO_ARTICLES: "PICK_TOTO_ARTICLES",
  TOTO_CLIENT_PICKS: "TOTO_CLIENT_PICKS",
  CREATE_POST: "CREATE_POST",
  CUSTOM_PROMPTS: "CUSTOM_PROMPTS",
  ALL_POSTS: "ALL_POSTS",
}

const SIDEBAR_LIST = [
  { key: SIDEBAR_ITEM.CLIENT_SUBHEADER, title: "" },
  {
    key: SIDEBAR_ITEM.NOTIFICATIONS,
    resource: RESOURCE.NOTIFICATIONS,
    title: "Notifications",
    pathname: "/notifications",
    parent: SIDEBAR_ITEM.CLIENT_SUBHEADER,
  },
  {
    key: SIDEBAR_ITEM.ONBOARDING,
    resource: RESOURCE.NOTIFICATIONS,
    title: "Onboarding",
    pathname: "/onboarding",
    parent: SIDEBAR_ITEM.CLIENT_SUBHEADER,
  },
  {
    key: SIDEBAR_ITEM.CALENDAR,
    resource: RESOURCE.CALENDAR,
    title: "Calendar",
    pathname: "/calendar",
    parent: SIDEBAR_ITEM.CLIENT_SUBHEADER,
  },
  { key: SIDEBAR_ITEM.PECSRR, title: "PECSRR", type: "subheader" },
  {
    key: SIDEBAR_ITEM.CREATE_PECSRR,
    resource: RESOURCE.CREATE_PECSRR,
    title: "Create PECSRR",
    pathname: "/pecsrr",
    parent: SIDEBAR_ITEM.PECSRR,
  },
  {
    key: SIDEBAR_ITEM.CREATE_POST,
    resource: RESOURCE.CREATE_POST,
    title: "Create Post",
    pathname: "/create-post",
    parent: SIDEBAR_ITEM.PECSRR,
  },
  {
    key: SIDEBAR_ITEM.ALL_PECSRR,
    resource: RESOURCE.ALL_PECSRR,
    title: "All PECSRR",
    pathname: "/all-pecsrr",
    parent: SIDEBAR_ITEM.PECSRR,
  },
  {
    key: SIDEBAR_ITEM.ALL_POSTS,
    resource: RESOURCE.ALL_POSTS,
    title: "All Posts",
    pathname: "/all-posts",
    parent: SIDEBAR_ITEM.PECSRR,
  },
  { key: SIDEBAR_ITEM.POST, title: "POST", type: "subheader" },
  {
    key: SIDEBAR_ITEM.SCHEDULE_LI_POST,
    resource: RESOURCE.SCHEDULE_LI_POST,
    title: "Schedule LI Post",
    pathname: "/post",
    parent: SIDEBAR_ITEM.POST,
  },
  {
    key: SIDEBAR_ITEM.ALL_LI_POSTS,
    resource: RESOURCE.ALL_LI_POSTS,
    title: "All LI Posts",
    pathname: "/posts",
    parent: SIDEBAR_ITEM.POST,
  },
  {
    key: SIDEBAR_ITEM.TOTO_ARTICLES,
    resource: RESOURCE.TOTO_ARTICLES,
    title: "TOTO Post Articles",
    pathname: "/toto-articles",
    parent: SIDEBAR_ITEM.POST,
  },
  {
    key: SIDEBAR_ITEM.TOTO_CLIENT_PICKS,
    resource: RESOURCE.TOTO_CLIENT_PICKS,
    title: "TOTO Client Picks",
    pathname: "/toto-client-picks",
    parent: SIDEBAR_ITEM.POST,
  },
  ...(canPickArticlesNow
    ? [
        {
          key: SIDEBAR_ITEM.PICK_TOTO_ARTICLES,
          resource: RESOURCE.PICK_TOTO_ARTICLES,
          title: "Pick TOTO Articles",
          pathname: "/pick-toto-articles",
          parent: SIDEBAR_ITEM.POST,
        },
      ]
    : []),
  { key: SIDEBAR_ITEM.CRUD_SUBHEADER, title: "", type: "subheader" },
  {
    key: SIDEBAR_ITEM.CREATE,
    title: "CREATE",
    type: "popover",
    parent: SIDEBAR_ITEM.CRUD_SUBHEADER,
  },
  {
    key: SIDEBAR_ITEM.CREATE_CLIENT,
    resource: RESOURCE.CREATE_CLIENT,
    title: "Create Client",
    pathname: "/new-client-name",
    parent: SIDEBAR_ITEM.CREATE,
  },
  {
    key: SIDEBAR_ITEM.CREATE_COMPANY,
    resource: RESOURCE.CREATE_COMPANY,
    title: "Create Company",
    pathname: "/new-client-company",
    parent: SIDEBAR_ITEM.CREATE,
  },
  {
    key: SIDEBAR_ITEM.CREATE_USER,
    resource: RESOURCE.CREATE_USER,
    title: "Create User",
    pathname: "/new-user",
    parent: SIDEBAR_ITEM.CREATE,
  },
  { key: SIDEBAR_ITEM.VIEW, title: "VIEW", type: "popover", parent: SIDEBAR_ITEM.CRUD_SUBHEADER },
  {
    key: SIDEBAR_ITEM.ALL_CLIENTS,
    resource: RESOURCE.ALL_CLIENTS,
    title: "All Clients",
    pathname: "/all-clients",
    parent: SIDEBAR_ITEM.VIEW,
  },
  {
    key: SIDEBAR_ITEM.ALL_COMPANIES,
    resource: RESOURCE.ALL_COMPANIES,
    title: "All Companies",
    pathname: "/all-companies",
    parent: SIDEBAR_ITEM.VIEW,
  },
  { key: SIDEBAR_ITEM.SETTINGS_SUBHEADER, title: "", type: "subheader" },
  {
    key: SIDEBAR_ITEM.SETTINGS,
    resource: RESOURCE.SETTINGS,
    title: "Settings",
    pathname: "/settings",
    parent: SIDEBAR_ITEM.SETTINGS_SUBHEADER,
  },
  { key: SIDEBAR_ITEM.SUPERADMIN_SUBHEADER, title: "", type: "subheader" },
  {
    key: SIDEBAR_ITEM.PLAYGROUND,
    resource: RESOURCE.PLAYGROUND,
    title: "Playground",
    pathname: "/sandbox",
    parent: SIDEBAR_ITEM.SUPERADMIN_SUBHEADER,
  },
  {
    key: SIDEBAR_ITEM.PROMPTS,
    resource: RESOURCE.PROMPTS,
    title: "All Prompts",
    pathname: "/prompts",
    parent: SIDEBAR_ITEM.SUPERADMIN_SUBHEADER,
  },
  {
    key: SIDEBAR_ITEM.POST_PROMPTS,
    resource: RESOURCE.POST_PROMPTS,
    title: "Post Prompts",
    pathname: "/post-prompts",
    parent: SIDEBAR_ITEM.SUPERADMIN_SUBHEADER,
  },
  {
    key: SIDEBAR_ITEM.CUSTOM_PROMPTS,
    resource: RESOURCE.CUSTOM_PROMPTS,
    title: "Custom Prompts",
    pathname: "/custom-prompts",
    parent: SIDEBAR_ITEM.SUPERADMIN_SUBHEADER,
  },
  {
    key: SIDEBAR_ITEM.OPENAI_KEY,
    resource: RESOURCE.OPENAI_KEY,
    title: "Open AI Key",
    pathname: "/openai-key",
    parent: SIDEBAR_ITEM.SUPERADMIN_SUBHEADER,
  },
]

const getTree = (arr, parent) =>
  arr
    .filter((item) => item.parent == parent)
    .map((item) => ({
      title: item.title,
      items: getTree(arr, item.key),
      type: item.type,
      pathname: item.pathname,
    }))

const SidebarItem = ({ item }) =>
  item.type === "popover" ? (
    item.items?.length > 0 && (
      <MouseoverMenu
        element={
          <ListItemButton>
            <Box
              display="flex"
              flexDirection="row"
              flex={1}
              alignItems="center"
              justifyContent="space-between"
            >
              <ListItemText primary={item.title.toUpperCase()} />
              <ChevronRightIcon />
            </Box>
          </ListItemButton>
        }
      >
        <List dense sx={{ width: "100%" }} component="nav" aria-labelledby="nested-list-subheader">
          {item.items.map((nestedItem, index) => (
            <SidebarItem key={index} item={nestedItem} />
          ))}
        </List>
      </MouseoverMenu>
    )
  ) : (
    <ListItemButton key={item.pathname} component="a" href={item.pathname}>
      <ListItemText primary={item.title} />
    </ListItemButton>
  )

function Sidebar() {
  const navigate = useNavigate()
  const { user, setUser } = useContext(UserContext)
  const role = user ? user.role : ""
  const tree = getTree(
    SIDEBAR_LIST.filter((item) => !item.resource || ac.can(role)["readAny"](item.resource).granted),
  )

  return (
    <ThemeProvider theme={darkTheme}>
      <div className="sidebar">
        <Paper sx={{ borderRadius: 0, flexDirection: "column", flexGrow: 1, display: "flex" }}>
          <Box
            component="img"
            src={clout_selling_logo}
            alt="clout selling logo"
            sx={{ margin: "48px auto", display: "block" }}
          />
          <Box
            sx={{
              flexDirection: "column",
              flexGrow: 1,
              display: "flex",
              justifyContent: "space-between",
            }}
          >
            <Box>
              {tree.map(({ title, items }, index) => (
                <List
                  key={index}
                  dense
                  component="nav"
                  {...(title && {
                    subheader: <ListSubheader component="div">{title.toUpperCase()}</ListSubheader>,
                  })}
                >
                  {items.map((item, index) => (
                    <SidebarItem key={index} item={item} />
                  ))}
                </List>
              ))}
            </Box>
            <List dense component="nav">
              <ListItemButton component="a" href='/feedback'>
                <ListItemText primary="Leave Feedback" />
              </ListItemButton>
              <ListItemButton
                onClick={() => {
                  setUser(null)
                  localStorage.clear()
                  navigate({ pathname: "/login" })
                  window.location.reload()
                }}
              >
                <ListItemText primary="LOG OUT" />
              </ListItemButton>
            </List>
          </Box>
        </Paper>
      </div>
    </ThemeProvider>
  )
}

export default Sidebar
