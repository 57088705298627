import { Typography, Button, Tabs, Tab, Stack, Dialog, Toolbar, AppBar } from "@mui/material"
import React, { useEffect } from "react"
import { styled } from "@mui/material/styles"

import Box from "@mui/material/Box"
import { useForm, FormProvider } from "react-hook-form"
import { getPrompt, savePrompt } from "../api/PromptService"
import { useSnackbar } from "notistack"
import TextFieldControlled from "../components/TextFieldControlled"
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined"
import HistoryIcon from "@mui/icons-material/History"
import IconButton from "@mui/material/IconButton"
import { useModal } from "mui-modal-provider"
import { server } from "../api"
import CloseIcon from "@mui/icons-material/Close"

export const PROMPT = {
  PECSRR_POST_WITH_TONE: "PECSRR_POST_WITH_TONE",
  PECSRR_TITLES: "PECSRR_TITLES",
  PECSRR_COMMENTS: "PECSRR_COMMENTS",
  PECSRR_REPLIES: "PECSRR_REPLIES",
  PECSRR_HASHTAGS: "PECSRR_HASHTAGS",
  LI_POST_REPLIES: "LI_POST_REPLIES",
  LI_POST_HASHTAGS: "LI_POST_HASHTAGS",
  CLIENT_TONE: "CLIENT_TONE",
  SUMMARY: "SUMMARY",
  EXCLUDED_WORDS: "EXCLUDED_WORDS",
}

const PromptTitle = {
  PECSRR_POST_WITH_TONE: "PECSRR Post With Tone",
  PECSRR_TITLES: "PECSRR Titles",
  PECSRR_COMMENTS: "PECSRR Comments",
  PECSRR_REPLIES: "PECSRR Replies",
  PECSRR_HASHTAGS: "PECSRR Hashtags",
  LI_POST_REPLIES: "LI Post Replies",
  LI_POST_HASHTAGS: "LI Post Hashtags",
  CLIENT_TONE: "Client Tone of Voice",
  SUMMARY: "Post Summary",
  EXCLUDED_WORDS: "Excluded Words",
}

const PromptForm = ({ name }) => {
  const form = useForm({ defaultValues: { name } })
  const { enqueueSnackbar } = useSnackbar()
  const { showModal } = useModal()

  useEffect(() => {
    ;(async () => {
      const { data } = await getPrompt(name)
      form.reset(data)
    })()
  }, [])

  const onSubmit = async (data) => {
    await savePrompt(data)
    enqueueSnackbar("Prompt saved!")
  }

  return (
    <FormProvider {...form}>
      <form onSubmit={form.handleSubmit(onSubmit)}>
        <TextFieldControlled
          fullWidth
          multiline
          name="prompt"
          // inputProps={{ sx: { fontFamily: "Monospace" } }}
        />
        {name === PROMPT.EXCLUDED_WORDS && (
          <>
            <br />
            <br />
            <span style={{ display: "flex", gap: 2 }}>
              <InfoOutlinedIcon />
              Included at the end of all prompts.
            </span>
          </>
        )}
        <Box sx={{ display: "flex", flexDirection: "row", justifyContent: "space-between", mt: 2 }}>
          <Button
            variant="outlined"
            startIcon={<HistoryIcon />}
            onClick={() => showModal(PromptVersionHistoryModal, { name })}
          >
            Version history
          </Button>
          <Button variant="contained" type="submit">
            Save prompt
          </Button>
        </Box>
      </form>
    </FormProvider>
  )
}

const StyledTab = styled(Tab)(({ theme }) => ({
  textTransform: "none",
}))

function TabPanel(props) {
  const { children, value, index, ...other } = props

  return (
    <Box
      sx={{ flexGrow: 1, px: 4 }}
      role="tabpanel"
      hidden={value !== index}
      id={`vertical-tabpanel-${index}`}
      aria-labelledby={`vertical-tab-${index}`}
      {...other}
    >
      {value === index && children}
    </Box>
  )
}

function a11yProps(index) {
  return {
    id: `vertical-tab-${index}`,
    "aria-controls": `vertical-tabpanel-${index}`,
  }
}

function Prompts(props) {
  const [value, setValue] = React.useState(0)

  const handleChange = (event, newValue) => {
    setValue(newValue)
  }

  return (
    <>
      <Typography variant="h5">All Prompts</Typography>
      <Stack direction="row" p={2}>
        <Tabs
          orientation="vertical"
          variant="scrollable"
          value={value}
          onChange={handleChange}
          aria-label="Vertical tabs example"
          sx={{ borderRight: 1, borderColor: "divider" }}
        >
          {Object.keys(PROMPT).map((prompt, index) => (
            <StyledTab label={PromptTitle[prompt]} {...a11yProps(index + 1)} />
          ))}
        </Tabs>
        {Object.keys(PROMPT).map((prompt, index) => (
          <TabPanel value={value} index={index}>
            <PromptForm name={prompt} />
          </TabPanel>
        ))}
      </Stack>
    </>
  )
}

// Create the dialog you want to use
const PromptVersionHistoryModal = ({ name, ...props }) => {
  return (
    <Dialog {...props} maxWidth="lg" fullWidth fullScreen>
      <AppBar sx={{ position: "relative", mb: 2 }}>
        <Toolbar>
          <IconButton edge="start" color="inherit" onClick={props.onClose} aria-label="close">
            <CloseIcon />
          </IconButton>
          <Typography sx={{ ml: 2, flex: 1 }} variant="h6" component="div">
            Version History
          </Typography>
        </Toolbar>
      </AppBar>
      <iframe
        width="100%"
        style={{ height: "100%" }}
        src={`${server}/ssr/prompt-version?name=${name}`}
        frameBorder="0"
      ></iframe>
    </Dialog>
  )
}

export default Prompts
