import React, { useState, useEffect } from "react";
import {
  Typography,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Button,
  TextField,
} from "@mui/material";
import { Snackbar, SnackbarContent } from "@mui/material";
import Modal from "@mui/material/Modal";
import Box from "@mui/material/Box";
import Autocomplete from "@mui/material/Autocomplete";
import Chip from "@mui/material/Chip";
import PacmanLoader from "react-spinners/PacmanLoader";
import { useNavigate } from "react-router-dom";
import { getActivePrompt } from "../api/superAdmin";

// openai
import { Configuration, OpenAIApi } from "openai";

import { getClientsNames, getClientsCompanies, savePECSRRPost } from "../api/client"
import { server } from "../api"
import useFetch from "../hooks/useFetch"
import { PROMPT } from "./Prompts"
import dataFetch from "../WrapPromise"
import {
  generatePECSRRCommentsNumbered,
  getCompletion,
  getCompletionStreamed,
  generatePostWithTone,
} from "../ChatGPTClient"
import { getSettings } from "../api/api"

const initialState = {
  problem: "",
  cost: "",
  solution: "",
  targetAud: "",
}

export const predefinedTags = [{ label: "LinkedIn" }, { label: "Sales" }, { label: "Leadership" }]

const resource = dataFetch()

function PecsRRPage(props) {
  const clientNames = useFetch(getClientsNames, [])
  const [companyName, setCompanyName] = useState([]);
  const [values, setValues] = useState(initialState);
  const [loading, setLoading] = useState(false);
  const [selectedClient, setSelectedClient] = useState("");
  const [selectedCompany, setSelectedCompany] = useState([]);
  const [counter, setCounter] = useState(0);
  const [pecsRR, setPecsRR] = useState("");
  const [pecsRRTitles, setPecsRRTitles] = useState("");
  const [pecsRRComments, setPecsComments] = useState("");
  const [pecsRRHashtags, setPecsHashtags] = useState("");
  const [repliesToComments, setRepliesToComments] = useState("");

  // set prompt
  const [postPrompt, setPostPrompt] = useState(``);

  const [showSnackbar, setShowSnackbar] = useState(false);
  const [generalDetails, setGeneralDetails] = useState([]);
  // for saving
  const [tags, setTags] = useState([]);
  const [openModal, setOpenModal] = useState(false);
  const [saved, setSaved] = useState(false);

  const user = JSON.parse(localStorage.getItem("user-data"))
  const user_id = user ? user.id : ""
  const navigate = useNavigate()
  const prompts = resource.prompts.read()
  const hashtagsPrompt = prompts.find((prompt) => prompt.name === PROMPT.PECSRR_HASHTAGS).prompt
  const repliesPrompt = prompts.find((prompt) => prompt.name === PROMPT.PECSRR_REPLIES).prompt
  const commentsPrompt = prompts.find((prompt) => prompt.name === PROMPT.PECSRR_COMMENTS).prompt
  const titlesPrompt = prompts.find((prompt) => prompt.name === PROMPT.PECSRR_TITLES).prompt
  const client = clientNames?.find(c => c._id === selectedClient)

  // get prompt
  useEffect(() => {
    // gets active prompt
    // eslint-disable-next-line
    getActivePrompt()
      .then((response) => {
        if (response) {
          // console.log(response.prompt);
          setPostPrompt(response.prompt);
        }
      })
      .catch((error) => {
        console.log(error); // Log any error
      });
  }, []);

  async function gptFourPrompt() {
    setLoading(true)
    let cleanedText
    if (client?.tonePrompt) {
      cleanedText = await generatePostWithTone({
        clientToneOfVoice: client?.tonePrompt,
        problem: values.problem,
        cost: values.cost,
        solution: values.solution,
        targetAudience: values.targetAud,
      })
    } else {
      const promptValue = postPrompt || ""
      const interpolatedPrompt = promptValue
        .replace("${values.problem}", values.problem)
        .replace("${values.cost}", values.cost)
        .replace("${values.solution}", values.solution)
        .replace("${values.targetAud}", values.targetAud)
      setPostPrompt(interpolatedPrompt)
      setLoading(true)
      const text = await getCompletionStreamed(interpolatedPrompt)
      cleanedText = text.replace(/(\n\n|\n)/, "")
    }
    setPecsRR(cleanedText)
    setLoading(false)
  }

  // prompt to write comments
  async function generateComments() {
    setLoading(true)
    const cleanedText = await generatePECSRRCommentsNumbered(commentsPrompt, pecsRR)
    setPecsComments(cleanedText)
    setLoading(false)
  }

  // prompt to write hashtags
  async function generatePECSRRHashtags() {
    setLoading(true)
    const prompt = hashtagsPrompt
      .replace("${targetAud}", values.targetAud)
      .replace("${pecsRRTitles}", pecsRRTitles)
    const cleanedText = await getCompletion(prompt)
    setPecsHashtags(cleanedText)
    setLoading(false)
  }

  // prompt to write replies
  async function gennerateRepliestoComments() {
    setLoading(true)
    const prompt = repliesPrompt.replace("${pecsRRComments}", pecsRRComments)
    const cleanedText = await getCompletion(prompt)
    setRepliesToComments(cleanedText)
    setLoading(false)
  }

  // set client companies
  useEffect(() => {
    // call the getClientsCompanies function with the selectedClient state as the id parameter
    if (selectedClient) {
      getClientsCompanies(selectedClient, (response) => {
        if (response.status === 200) {
          // console.log("company");
          // console.log(response.data[0].ProductService);
          setCompanyName(response.data[0].ProductService);
          setGeneralDetails(response.data[0]);
          setValues({ ...values, targetAud: response.data[0].IdealCustomer });
          // update the state with the response data
          // console.log(response.data[0]);
        } else {
          console.error(response);
        }
      });
    } // eslint-disable-next-line
  }, [selectedClient]);

  useEffect(() => {
    if (pecsRR !== "") {
      if (
        pecsRRTitles === "" &&
        pecsRRComments === "" &&
        pecsRRHashtags === ""
      ) {
        generatePECSRRHeadlines()
        generateComments()
      }
    }
    // eslint-disable-next-line
  }, [pecsRR]);

  useEffect(() => {
    if (pecsRRTitles !== "") {
      generatePECSRRHashtags()
    }
    // eslint-disable-next-line
  }, [pecsRRTitles]);

  useEffect(() => {
    if (pecsRRComments !== "") {
      gennerateRepliestoComments();
    }
    // eslint-disable-next-line
  }, [pecsRRComments]);

  const generatePECSRRHeadlines = async () => {
    setLoading(true)
    const {
      data: { model },
    } = await getSettings()
    const prompt = titlesPrompt.replace("${pecsRR}", pecsRR)
    const configuration = new Configuration({
      basePath: `${server}/v1`,
      organization: "org-SfyUsC5kfHAByNkg7W3z8yGL",
    })
    delete configuration.baseOptions.headers["User-Agent"]
    const openai = new OpenAIApi(configuration)
    try {
      const completion = await openai.createChatCompletion({
        temperature: 1,
        max_tokens: 1400,
        top_p: 1,
        frequency_penalty: 0,
        presence_penalty: 0,
        model,
        messages: [
          {
            role: "user",
            content: prompt,
          },
        ],
      })
      setPecsRRTitles(completion.data.choices[0].message.content)
    } catch (error) {
      console.log(error)
    } finally {
      setLoading(false)
    }
  }

  // for the modal
  const handleModal = () => {
    setOpenModal(!openModal);
  };

  const handleClientNameChange = (event) => {
    setSelectedClient(event.target.value);
  };

  const handleClientCompanyChange = (event) => {
    setSelectedCompany(event.target.value);
    console.log(event.target.value);
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setValues({ ...values, [name]: value });
  };

  // handles snackbar close
  const handleSnackbarClose = () => {
    setShowSnackbar(false);
  };

  const renderFields = () => {
    switch (counter) {
      case 0:
        return (
          <div className="select-client-name">
            <Typography variant="subtitle1">
              Select a Client to Generate PECSRR
            </Typography>
            <FormControl fullWidth style={{ marginTop: "20px" }}>
              <InputLabel id="client-select-label">Select Client</InputLabel>
              <Select
                labelId="client-select-label"
                id="client-select"
                value={selectedClient}
                label="Select Client"
                onChange={handleClientNameChange}
              >
                {clientNames?.map((item) => (
                  <MenuItem key={item._id} value={item._id}>
                    {item.name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
            <Button
              variant="contained"
              disabled={selectedClient === ""}
              sx={{
                mt: 2,
                bgcolor: "#F16334",
                color: "white",
                "&:hover": {
                  bgcolor: "#F16334",
                },
              }}
              onClick={() => {
                setCounter(counter + 1);
              }}
            >
              Select
            </Button>
          </div>
        );

      case 1:
        return (
          <div className="select-client-company">
            <Typography
              variant="subtitle1"
              onClick={() => {
                console.log(generalDetails);
              }}
            >
              Select Objective for:{" "}
              {
                clientNames.find((client) => client._id === selectedClient)
                  ?.name
              }
            </Typography>

            <FormControl fullWidth style={{ marginTop: "20px" }}>
              <InputLabel id="client-select-label">Select Objective</InputLabel>
              <Select
                labelId="client-select-label"
                id="client-select"
                value={selectedCompany}
                label="Select Company"
                onChange={handleClientCompanyChange}
              >
                {companyName.map((product) => (
                  <MenuItem key={product} value={product}>
                    {product}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
            <Button
              variant="contained"
              disabled={selectedCompany === ""}
              sx={{
                mt: 2,
                bgcolor: "#F16334",
                color: "white",
                "&:hover": {
                  bgcolor: "#F16334",
                },
              }}
              onClick={() => {
                setCounter(counter + 1);
              }}
            >
              Select
            </Button>
            <Button
              variant="caption"
              className="back"
              style={{ marginTop: "10px" }}
              onClick={() => {
                setCounter(counter - 1);
              }}
            >
              Back
            </Button>
          </div>
        );

      case 2:
        return (
          <div className="pecsrr-input">
            <Typography
              variant="subtitle1"
              onClick={() => {
                console.log(generalDetails);
              }}
            >
              Write PECSRR for: {selectedCompany}
            </Typography>
            <div className="problem-container">
              <Typography className="problem-text" variant="body2">
                Problem: <br />
                {generalDetails.Problem || ""}
              </Typography>
            </div>
            <Typography></Typography>
            <TextField
              name="problem"
              label="Problem"
              value={values.problem}
              onChange={handleChange}
              fullWidth
              multiline
              rows={2}
              style={{ marginTop: "15px" }}
            />
            <TextField
              name="cost"
              label="Cost"
              value={values.cost}
              onChange={handleChange}
              fullWidth
              multiline
              rows={2}
              style={{ marginTop: "15px" }}
            />
            <TextField
              name="solution"
              label="Solution"
              value={values.solution}
              onChange={handleChange}
              fullWidth
              multiline
              rows={2}
              style={{ marginTop: "15px" }}
            />
            <TextField
              name="targetAud"
              label="Target Audience"
              value={values.targetAud}
              onChange={handleChange}
              fullWidth
              multiline
              rows={4}
              style={{ marginTop: "15px" }}
            />
            <div
              style={{ marginTop: "15px" }}
              className="linkedin-link-container"
            >
              <Typography
                onClick={() => {
                  window.open(generalDetails.LinkedIn, "_blank");
                }}
                className="linkedin-link"
                variant="caption"
                style={{
                  marginTop: "20px",
                  textAlign: "left",
                }}
              >
                LinkedIn Profile: {generalDetails.LinkedIn}
              </Typography>
            </div>

            <Button
              variant="contained"
              sx={{
                mt: 2,
                bgcolor: "#F16334",
                color: "white",
                "&:hover": {
                  bgcolor: "#F16334",
                },
              }}
              onClick={() => {
                // console.log(values);
                gptFourPrompt();
                setCounter(counter + 1);
              }}
            >
              Submit
            </Button>
            <Button
              variant="caption"
              className="back-button"
              style={{ marginTop: "20px" }}
              onClick={() => {
                console.log(loading);
                setCounter(counter - 1);
              }}
            >
              Back
            </Button>
          </div>
        );

      case 3:
        return (
          <>
            <div className="pecsrr-output">
              {pecsRR === "" ? (
                <div className="loader-container">
                  <PacmanLoader
                    color={"#212C44"}
                    aria-label="Loading Spinner"
                    data-testid="loader"
                    size={25}
                  />
                  <Typography variant="subtitle2" style={{ marginTop: "20px" }}>
                    Writing your post....
                  </Typography>
                </div>
              ) : (
                <div>
                  {pecsRRTitles !== "" &&
                  pecsRRComments !== "" &&
                  pecsRRHashtags !== "" &&
                  repliesToComments !== "" ? (
                    <div className="pecsrr-result-with-title">
                      <div className="postbody-container">
                        <div
                          style={{ marginBottom: "20px" }}
                          className="space-between"
                        >
                          {/* {" POST "} */}
                          <Typography
                            variant="h6"
                            style={{ marginTop: "20px" }}
                            onClick={() => {
                              console.log(pecsRRHashtags);
                            }}
                          >
                            Post:{" "}
                          </Typography>
                          {saved === true ? (
                            "Post Already Saved!"
                          ) : (
                            <Button
                              variant="caption"
                              sx={{
                                bgcolor: "#F16334",
                                color: "white",
                                "&:hover": {
                                  bgcolor: "#F16334",
                                },
                              }}
                              onClick={handleModal}
                            >
                              Save Post
                            </Button>
                          )}
                        </div>

                        <Typography variant="body2" className="response">
                          {pecsRR.replace(
                            /problem: |empathy: |cost of inaction: |solution: |results: /gi,
                            ""
                          )}
                        </Typography>
                        <div className="space-between">
                          <Button
                            variant="caption"
                            style={{ marginTop: "20px" }}
                            sx={{
                              mt: 2,
                              bgcolor: "#212C44",
                              color: "white",
                              "&:hover": {
                                bgcolor: "#212C44",
                              },
                            }}
                            onClick={() => {
                              setPecsRR("");
                              gptFourPrompt();
                              setSaved(false);
                            }}
                          >
                            Regenerate Post
                          </Button>
                          <Button
                            variant="caption"
                            style={{ marginTop: "20px" }}
                            sx={{
                              mt: 2,
                              bgcolor: "#F16334",
                              color: "white",
                              "&:hover": {
                                bgcolor: "#F16334",
                              },
                            }}
                            onClick={() => {
                              setShowSnackbar(true);
                              navigator.clipboard.writeText(
                                pecsRR.replace(
                                  /problem: |empathy: |cost of inaction: |solution: |results: /gi,
                                  ""
                                )
                              );
                            }}
                          >
                            Copy to Clipboard
                          </Button>
                        </div>
                      </div>
                      {/* titles */}
                      <div className="item-container">
                        {" "}
                        <Typography variant="h6">Titles: </Typography>
                        <Typography variant="body2" className="response">
                          {pecsRRTitles}
                        </Typography>
                        <Button
                          variant="caption"
                          style={{ marginTop: "20px" }}
                          sx={{
                            mt: 2,
                            bgcolor: "#212C44",
                            color: "white",
                            "&:hover": {
                              bgcolor: "#212C44",
                            },
                          }}
                          onClick={() => {
                            setPecsRRTitles("");
                            generatePECSRRHeadlines();
                            setSaved(false);
                          }}
                        >
                          Regenerate Titles
                        </Button>
                      </div>
                      {/* comments */}
                      <div className="item-container">
                        {" "}
                        <Typography variant="h6">Comments: </Typography>
                        <Typography variant="body2" className="response">
                          {pecsRRComments}
                        </Typography>
                        <Button
                          variant="caption"
                          style={{ marginTop: "20px" }}
                          sx={{
                            mt: 2,
                            bgcolor: "#212C44",
                            color: "white",
                            "&:hover": {
                              bgcolor: "#212C44",
                            },
                          }}
                          onClick={() => {
                            // set comments to empty to ttrigger loading
                            setPecsComments("");
                            // set replies to empty
                            setRepliesToComments("");

                            // call functions to regenerate replies as well as commetns
                            generateComments()
                            gennerateRepliestoComments()
                            setSaved(false)
                          }}
                        >
                          Regenerate Comments
                        </Button>
                      </div>
                      {/* Replies */}
                      <div className="item-container">
                        {" "}
                        <Typography variant="h6">
                          Replies to Comments:{" "}
                        </Typography>
                        <Typography variant="body2" className="response">
                          {repliesToComments}
                        </Typography>
                        <Button
                          variant="caption"
                          style={{ marginTop: "20px" }}
                          sx={{
                            mt: 2,
                            bgcolor: "#212C44",
                            color: "white",
                            "&:hover": {
                              bgcolor: "#212C44",
                            },
                          }}
                          onClick={() => {
                            setRepliesToComments("");
                            gennerateRepliestoComments();
                            setSaved(false);
                          }}
                        >
                          Regenerate Replies
                        </Button>
                      </div>
                      {/* hastags */}
                      <div className="item-container">
                        {" "}
                        <Typography variant="h6">Hashtags: </Typography>
                        <Typography variant="body2" className="response">
                          {pecsRRHashtags}
                        </Typography>
                        <Button
                          variant="caption"
                          style={{ marginTop: "20px" }}
                          sx={{
                            mt: 2,
                            bgcolor: "#212C44",
                            color: "white",
                            "&:hover": {
                              bgcolor: "#212C44",
                            },
                          }}
                          onClick={() => {
                            setPecsHashtags("");
                            generatePECSRRHashtags();
                            setSaved(false);
                          }}
                        >
                          Regenerate Hashtags
                        </Button>
                      </div>
                    </div>
                  ) : (
                    <div className="loader-container">
                      <PacmanLoader
                        color={"#212C44"}
                        aria-label="Loading Spinner"
                        data-testid="loader"
                        size={25}
                      />
                      <Typography
                        variant="subtitle2"
                        style={{ marginTop: "20px" }}
                      >
                        Creating titles and comments for your post....
                      </Typography>
                    </div>
                  )}
                </div>
              )}
            </div>
          </>
        );

      default:
        break;
    }
  };

  return (
    <>
      {pecsRR ? (
        <Button
          onClick={() => {
            window.location.reload();
          }}
        >
          New PECSRR Post
        </Button>
      ) : (
        ""
      )}
      <div className="pecssrr-container">
        <div className="pecssrr-form">{renderFields()}</div>
      </div>
      <Modal
        open={openModal}
        onClose={handleModal}
        aria-labelledby="modal-title"
        aria-describedby="modal-description"
      >
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: 400,
            bgcolor: "#212C44",
            borderRadius: "4px",
            boxShadow: 24,
            p: 4,
          }}
        >
          <Typography variant="h6" id="modal-title" style={{ color: "white" }}>
            Save PECSRR post?
          </Typography>
          <form
            onSubmit={(e) => {
              e.preventDefault();
              const data = {
                title: pecsRRTitles,
                clientCompany: generalDetails._id,
                clientName: clientNames.find(
                  (client) => client._id === selectedClient
                )?._id,
                generatedContent: pecsRR.replace(
                  /problem: |empathy: |cost of inaction: |solution: |results: /gi,
                  ""
                ),
                comments: pecsRRComments,
                hashtags: pecsRRHashtags,
                replies: repliesToComments,
                tags: tags,
                category: "",
                user: user_id,
                promptProblem: values.problem,
                promptCost: values.cost,
                promptSolution: values.solution,
              };
              console.log(data);
              savePECSRRPost(data, (response) => {
                console.log(response);
                setSaved(true);
                setOpenModal(false);
              });

              navigate({
                pathname: "/all-pecsrr",
              });
            }}
          >
            <TextField
              label="Client Name"
              value={
                clientNames.find((client) => client._id === selectedClient)
                  ?.name
              }
              fullWidth
              style={{ marginTop: "15px", marginBottom: "15px" }}
              InputLabelProps={{
                style: { color: "#C0C9E1" },
              }}
              InputProps={{
                style: { color: "#C0C9E1", background: "#3B4C6E" },
              }}
            />
            <TextField
              label="Client Company"
              value={selectedCompany}
              fullWidth
              style={{ marginTop: "15px", marginBottom: "15px" }}
              InputLabelProps={{
                style: { color: "#C0C9E1" },
              }}
              InputProps={{
                style: { color: "#C0C9E1", background: "#3B4C6E" },
              }}
            />
            <Autocomplete
              multiple
              id="tags-filled"
              options={predefinedTags.map((option) => option.label)}
              freeSolo
              value={tags}
              onChange={(event, newValue) => {
                setTags(newValue);
              }}
              renderTags={(value, getTagProps) =>
                value.map((option, index) => (
                  <Chip
                    variant="outlined"
                    label={option}
                    {...getTagProps({ index })}
                    style={{
                      marginBottom: "10px",
                      marginTop: "10px",
                      color: "white",
                    }}
                  />
                ))
              }
              renderInput={(params) => (
                <TextField
                  {...params}
                  variant="filled"
                  label="Tags"
                  placeholder="Add tags"
                  InputLabelProps={{
                    style: { color: "white" },
                  }}
                  InputProps={{
                    ...params.InputProps,
                    style: { color: "white", background: "#3B4C6E" },
                  }}
                />
              )}
            />
            <Button
              type="submit"
              variant="contained"
              sx={{
                bgcolor: "#F16334",
                color: "white",
                "&:hover": {
                  bgcolor: "#F16334",
                },
                marginTop: "15px",
              }}
            >
              Save
            </Button>
          </form>
        </Box>
      </Modal>

      <Snackbar
        open={showSnackbar}
        autoHideDuration={3000}
        onClose={handleSnackbarClose}
        anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
      >
        <SnackbarContent message="Text copied to clipboard" />
      </Snackbar>
    </>
  );
}

export default PecsRRPage;
